import { template as template_a9268866b47c4fe0a23c63225bb5641d } from "@ember/template-compiler";
const WelcomeHeader = template_a9268866b47c4fe0a23c63225bb5641d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
