import { template as template_ff50a3d26c1e42a5bb3bcc522eb20e83 } from "@ember/template-compiler";
const FKLabel = template_ff50a3d26c1e42a5bb3bcc522eb20e83(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
