import { template as template_6e8518ce7ec84523919198aaf9ff9978 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6e8518ce7ec84523919198aaf9ff9978(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
