import { template as template_29b956bf452a4564b0219458b3f50a5f } from "@ember/template-compiler";
const FKText = template_29b956bf452a4564b0219458b3f50a5f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
